import React, { useEffect } from "react";
import timer from "../../assets/img/timer.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserAttendenceData } from "../../redux/Action";
import { calcLength } from "framer-motion";
import ProgressbarTimer from "../../components/common/progressbar/ProgressbarTimer";
import { Flex, Progress } from "antd";
import moment from "moment";

const AttendanceOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const pathParts = pathname.split("/");

  const userFullAttendenceData = useSelector(
    (state) => state.main?.userFullAttendenceData?.data
  );
  const userId = pathParts[pathParts.length - 1];

  useEffect(() => {
    dispatch(getUserAttendenceData(userId));
  }, [userId]);

  const todayRawTime =
    userFullAttendenceData?.todayAttendance?.working_hour || "0h 0m";
  const weeklyTotalWorkHours = parseFloat(
    userFullAttendenceData?.weeklyTotalWorkHours
  );
  const overTimeDays = parseInt(userFullAttendenceData?.overTimeDays) || 0;
  const WeekWorkingHours = parseFloat(
    userFullAttendenceData?.thisWeekWorkingHours
  );
  const dailyTargetHours = 8;
  const maxOvertimeDays = 5;
  let todayWorkingHours = 0;
  console.log(WeekWorkingHours, weeklyTotalWorkHours);

  if (typeof todayRawTime === "string") {
    const hoursMatch = todayRawTime.match(/(\d+)h/);

    const minutesMatch = todayRawTime.match(/(\d+)m/);

    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    todayWorkingHours = hours + minutes / 60;
  } else if (!isNaN(parseFloat(todayRawTime))) {
    todayWorkingHours = parseFloat(todayRawTime);
  }

  const todayProgress = Math.min(
    (todayWorkingHours / dailyTargetHours) * 100,
    100
  );
  // const weekProgress = Math.min(
  //   (WeekWorkingHours / weeklyTotalWorkHours) * 100
  // );
  const overtimeProgress = Math.min(
    (overTimeDays / maxOvertimeDays) * 100,
    100
  );

  return (
    <div className="overview container">
      <div className="row d-flex  justify-content-start">
        <div className="col-6">
          <div
            className="card"
            style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
          >
            <div className="card-body ">
              <div className="d-flex justify-content-around  align-item-center">
                <p
                  style={{
                    fontSize: "20px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    color: "#2C357D",
                    paddingBottom: "10",
                  }}
                >
                  Statistics
                </p>
                <div className="d-flex flex-column">
                  {/* <span>
                      <img src={timer} alt="Timer icon" />
                    </span> */}
                  <ProgressbarTimer
                    userFullAttendenceData={userFullAttendenceData}
                  />
                </div>
                <p
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "500",
                    color: "#2C357D",
                    paddingBottom: "10",
                  }}
                >
                  Today’s : Status
                  <br />
                  {userFullAttendenceData?.todayAttendance?.status ==
                  "Late-Arrival"
                    ? "Late"
                    : userFullAttendenceData?.todayAttendance?.status}
                </p>
              </div>
              <div className=" mx-auto" style={{ width: "95%" }}>
                <div
                  className="d-flex justify-content-between align-item-center pt-2 pb-2"
                  style={{ color: "#2C357D" }}
                >
                  {/* <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "300",
                    }}
                  > */}
                  {/* </span> */}
                  {/* <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                    }}
                  > */}
                  <Flex vertical gap="small" style={{ width: " 100% " }}>
                    {/* Today's Work */}
                    {/* <div>
                      Today: {todayWorkingHours.toFixed(2)} / {dailyTargetHours}{" "}
                      hrs
                      <Progress
                        percent={todayProgress}
                        size="small"
                        strokeColor="#2C357D"
                        trailColor="#D9D9D9"
                      />
                    </div> */}

                    {/* Weekly Work */}
                    <div>
                      Week: {WeekWorkingHours.toFixed(2)} /{" "}
                      {weeklyTotalWorkHours} hrs
                      <Progress
                        percent={(
                          (WeekWorkingHours / weeklyTotalWorkHours) *
                          100
                        ).toFixed(2)}
                        size="small"
                        status="normal"
                        strokeColor="#2C357D"
                        l
                        trailColor="#D9D9D9"
                      />
                    </div>

                    {/* Overtime Per Week */}
                    <div>
                      Overtime Per Week: {overTimeDays} / {maxOvertimeDays} days
                      <Progress
                        percent={overtimeProgress}
                        size="small"
                        status="normal"
                        strokeColor="#2C357D"
                        trailColor="#D9D9D9"
                      />
                    </div>
                  </Flex>
                  {/* </span> */}
                </div>
                {/* <div className="progress" style={{ height: "8px" }}>
                  <div
                    className="progress-bar w-70"
                    style={{ backgroundColor: "#2C357D" }}
                    role="progressbar"
                  ></div>
                </div>
                <div
                  className="d-flex justify-content-between align-item-center pt-2 pb-2"
                  style={{ color: "#2C357D" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "300",
                    }}
                  >
                    Week
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {userFullAttendenceData?.weeklyTotalWorkHours}hrs
                  </span>
                </div>
                <div className="progress" style={{ height: "8px" }}>
                  <div
                    className="progress-bar w-50"
                    style={{ backgroundColor: "#2C357D" }}
                    role="progressbar"
                  ></div>
                </div>
                <div
                  className="d-flex justify-content-between align-item-center pt-2 pb-2"
                  style={{ color: "#2C357D" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "300",
                    }}
                  >
                    Overtime Per Week
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                    }}
                  >
                    {userFullAttendenceData?.overTimeDays} days
                  </span>
                </div>
                <div className="progress" style={{ height: "8px" }}>
                  <div
                    className="progress-bar w-50"
                    style={{ backgroundColor: "#2C357D" }}
                    role="progressbar"
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div
            className="card"
            style={{ boxShadow: "0px 1px 15px 0px #b6bacb4d" }}
          >
            <div className="card-body">
              <div className="d-flex justify-content-between align-item-center gap-2 mb-3">
                <p
                  style={{
                    fontSize: "20px",
                    lineHeight: "16px",
                    fontWeight: "500",
                    color: "#2C357D",
                    width: "100%",
                  }}
                >
                  Timesheet
                </p>
              </div>
              <div className="d-flex">
                <div className=" mx-auto" style={{ width: "50%" }}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-3 ">
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#005EFF",
                            marginBottom: "9px",
                          }}
                        >
                          Check In :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#005EFF",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.check_in_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.check_in_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#005EFF",
                            marginBottom: "9px",
                          }}
                        >
                          Manager Check In :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#005EFF",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.manager_check_in_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.manager_check_in_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#005EFF",
                            marginBottom: "9px",
                          }}
                        >
                          Manager Check In Approved :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#005EFF",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.manager_approve_check_in_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.manager_approve_check_in_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#005EFF",
                            marginBottom: "9px",
                          }}
                        >
                          Check In Auto Approved :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#005EFF",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.auto_approve_check_in_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.auto_approve_check_in_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mx-auto" style={{ width: "50%" }}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-3 ">
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#FF0000",
                            marginBottom: "9px",
                          }}
                        >
                          Check Out :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#FF0000",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.check_out_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.check_out_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#FF0000",
                            marginBottom: "9px",
                          }}
                        >
                          Manager Check Out :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#FF0000",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.manager_check_out_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.manager_check_out_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#FF0000",
                            marginBottom: "9px",
                          }}
                        >
                          Manager Check Out Approved :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#FF0000",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.manager_approve_check_out_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.manager_approve_check_out_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                      <div className="d-flex w-100 flex-column align-items-start">
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "500",
                            color: "#FF0000",
                            marginBottom: "9px",
                          }}
                        >
                          Check Out Auto Approved :
                        </span>
                        <span
                          style={{
                            marginTop: "5px",
                            fontSize: "16px",
                            lineHeight: "16px",
                            fontWeight: "300",
                            color: "#FF0000",
                          }}
                        >
                          {userFullAttendenceData?.todayAttendance
                            ?.auto_approve_check_out_time
                            ? moment(
                                userFullAttendenceData?.todayAttendance
                                  ?.auto_approve_check_out_time
                              ).format("dddd, MMMM Do YYYY, h:mm A")
                            : "-"}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container" style={{ padding: "15px 0" }}>
        <table className="table" style={{ textAlign: "left" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Month</th>
              <th style={{ textAlign: "left" }}>Total Days (TD)</th>
              <th style={{ textAlign: "left" }}>Present</th>
              <th style={{ textAlign: "left" }}>Late (LT)</th>
              <th style={{ textAlign: "left" }}>Absent (A)</th>
              <th style={{ textAlign: "left" }}>Leave (L)</th>
              <th style={{ textAlign: "left" }}>Off</th>
              {/* <th style={{ textAlign: "left" }}>Holiday (HO)</th> */}
              <th style={{ textAlign: "left" }}>Half Day (H/D)</th>
              <th style={{ textAlign: "left" }}>Working Days </th>
            </tr>
          </thead>
          <tbody>
            {userFullAttendenceData?.yearlyAttendanceReport?.map(
              (data, index) => (
                <tr key={index}>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.monthName}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.totalDays}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.presentDays}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.lateDays}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.absent}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.leaveDays}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.off}
                  </td>
                  {/* <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.holiday}
                  </td> */}
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.halfDay}
                  </td>
                  <td style={({ padding: "8px 0" }, { textAlign: "left" })}>
                    {data.totalWorkingDays}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AttendanceOverview;
