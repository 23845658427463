import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./reports.css";
import ViewUser from "../../Admin/pages/Users/ViewUser";
import { getReportList, getStoreSingleDetails } from "../../redux/Action";
import greydrop from "../../assets/img/greydrop.png";

import moment from "moment";
import Pagination from "../Pagination/Pagination";
import VIewReportUser from "./VIewReportUser";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";

const ViewReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { allReportList, allStoreSingleDetail } = useSelector(
    (state) => state.main
  );
  const pageLoading = useSelector((state) => state.main.loading);

  const userRole = localStorage.getItem("role");
  const [onRequest, setOnRequest] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pathname = location.pathname;
  const pathParts = pathname.split("/");
  const userId = pathParts[pathParts.length - 1];
  useEffect(() => {
    if (userRole === "management") {
      dispatch(getStoreSingleDetails(userId, currentPage));
    } else {
      dispatch(getReportList(currentPage));
    }
  }, [dispatch, userRole, userId, currentPage]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/report/viewreports/viewreportuser/${data?.employee_id}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Present":
        return "#252C58";
      case "Absent":
        return "#AA0000";
      case "Late arrival":
        return "#D5B500";
      default:
        return "#2C357D";
    }
  };

  const reportData =
    userRole === "management"
      ? allStoreSingleDetail?.data?.storeUsers || []
      : allReportList?.data?.attendances || [];

  const paginationdata =
    userRole === "admin" ? allReportList : allStoreSingleDetail;

  // console.log(paginationdata?.meta, "paginationdata", userRole, "userRole");

  return (
    <>
      <div className="content-wrapper">
        <div className="store_card">
          <div className="store_text">
            {userRole === "management"
              ? allStoreSingleDetail?.data?.storeDetails?.name || "Store Name"
              : allReportList?.data?.storDetails?.name || "Store Name"}
          </div>
          <div
            className="container-fluid view-requestr"
            style={{ borderTop: "3.58px solid #d5d9dd" }}
          >
            <div className="table-responsive">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      {[
                        { name: "Employee ID", icon: null },
                        { name: "Employee Name", icon: null },
                        { name: "Role", icon: greydrop },
                        { name: "Designation", icon: greydrop },
                        { name: "Date", icon: null },
                        { name: "Check-in", icon: greydrop },
                        { name: "Manager Checkin", icon: greydrop },
                        { name: "Check-out", icon: greydrop },
                        { name: "Manager checkout", icon: greydrop },
                        { name: "Work hours", icon: greydrop },
                        { name: "Status", icon: greydrop },
                        { name: "Action", icon: null },
                      ].map((column, index) => (
                        <th key={index}>
                          {column.name}
                          {column.icon && (
                            <img
                              src={column.icon}
                              alt={`${column.name} dropdown icon`}
                              className="header-dropdown-icon"
                              onClick={() =>
                                console.log(
                                  `Dropdown clicked for ${column.name}`
                                )
                              }
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(reportData, "hdskjkj")} */}
                    {reportData?.map((report) => (
                      <tr key={report.id}>
                        <td>{report.employee_emp_id}</td>
                        <td>{report.employee_name}</td>
                        <td style={{ opacity: "50%" }}>
                          {report.employee_role}
                        </td>
                        <td style={{ opacity: "50%" }}>
                          {report.employee_designation}
                        </td>
                        <td style={{ opacity: "50%" }}>
                          {moment(report.date).format("DD-MM-YYYY")}
                        </td>
                        <td style={{ color: getStatusColor(report.status) }}>
                          {report.check_in_time
                            ? moment(report.check_in_time).format("hh:mm A")
                            : "-"}
                        </td>
                        <td style={{ color: getStatusColor(report.status) }}>
                          {report.manager_check_in_time
                            ? moment(report.manager_check_in_time).format(
                                "hh:mm A"
                              )
                            : "-"}
                        </td>
                        <td style={{ color: getStatusColor(report.status) }}>
                          {report.check_out_time
                            ? moment(report.check_out_time).format("hh:mm A")
                            : "-"}
                        </td>
                        <td style={{ color: getStatusColor(report.status) }}>
                          {report.manager_check_out_time
                            ? moment(report.manager_check_out_time).format(
                                "hh:mm A"
                              )
                            : "-"}
                        </td>
                        <td style={{ color: getStatusColor(report.status) }}>
                          {report.working_hour}
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              report.status === "Present"
                                ? "badge-approved"
                                : report.status === "Absent"
                                ? "badge-rejected"
                                : "badge-pending"
                            }`}
                          >
                            {report.status == "Late-Arrival"
                              ? "Late"
                              : report.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn-view"
                            onClick={() => OpenRequest(report)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <Pagination
                listData={paginationdata?.meta}
                setPageData={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      {onRequest && <VIewReportUser />}
    </>
  );
};

export default ViewReports;
