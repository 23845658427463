// Dropdown.js
import React, { useState, useRef } from "react";
import dropdown_custom from "../../../assets/icons/dropdown_custom.svg";

const Dropdown = ({
  data,
  value,
  onChange,
  bgColor = "#fff",
  textColor = "#252C58",
  fontSize = "12px",
  fontWeight = "500",
  lineHeight = "20px",
  textAlign = "left",
  clearSection,
  hide,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (selectedValue) => {
    onChange(selectedValue); // Notify parent component
    setIsOpen(false); // Close dropdown after selection
  };

  const handleClear = (e) => {
    e.stopPropagation(); // Prevent dropdown toggle when clicking clear
    clearSection({});
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={{
        backgroundColor: bgColor,
        border: "1px solid #F0F0F0",
        color: textColor,
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        textAlign: textAlign,
        padding: "0 10px",
        width: "200px",
        borderRadius: "4px",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {type !== "input" ? (
        <div
          onClick={toggleDropdown}
          style={{
            cursor: "pointer",
            padding: "5px 10px",
            backgroundColor: bgColor,
            border: "none",
            color: textColor,
            fontSize: fontSize,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            textAlign: textAlign,
            outline: "none",
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          {value ? value : "Select an option"}
        </div>
      ) : (
        <input
          type="text"
          style={{
            padding: "5px",
            backgroundColor: bgColor,
            border: "none",
            color: textColor,
            fontSize: fontSize,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            textAlign: textAlign,
            outline: "none",
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
          }}
          placeholder="Search"
          value={value}
          onChange={onChange}
        />
      )}

      {type !== "input" && !hide && value ? (
        <svg
          width="25"
          height="30"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleClear}
          // style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <rect
            width="60"
            height="60"
            rx="30"
            fill="#2C357D"
            fillOpacity="0.15"
          />
          <path
            d="M37.75 22.1377L21.25 38.6377"
            stroke="#2C357D"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.25 22.1377L37.75 38.6377"
            stroke="#2C357D"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        type !== "input" && (
          <img
            src={dropdown_custom}
            alt="dropdown icon"
            style={{ cursor: "pointer" }}
            onClick={toggleDropdown}
          />
        )
      )}

      {type !== "input" && isOpen && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            color: textColor,
            margin: 0,
            padding: 0,
            listStyle: "none",
            border: "1px solid #F0F0F0",
            boxShadow: "0px 4px 4px 0px #00000040",
            zIndex: 100,
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {data?.map((item, index) => (
            <li
              key={index}
              onClick={() => !item?.disabled && handleSelect(item)}
              style={{
                padding: "10px",
                cursor: item?.disabled ? "not-allowed" : "pointer",
                backgroundColor: item?.disabled ? "#f0f0f0" : "#fff",
                color: item?.disabled ? "#b0b0b0" : textColor,
                fontSize: fontSize,
                fontWeight: fontWeight,
                lineHeight: lineHeight,
                textAlign: textAlign,
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
