import React, { useEffect, useState } from "react";
import {
  IconDelete,
  IconEdit,
  IconCloudUp,
  IconDown,
  IconSearch,
} from "../../assets/img/dashboard/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getReportList } from "../../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import SpinnerLoader from "../../components/common/spinnerloader/SpinnerLoader";
import greydrop from "../../assets/img/greydrop.png";

const Attendance = () => {
  const userRole = localStorage.getItem("role");
  const { allReportList } = useSelector((state) => state.main);
  const pageLoading = useSelector((state) => state.main.loading);

  const [onRequest, setOnRequest] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [datedash, setDateDash] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!datedash) {
      setDateDash(moment().format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    if (searchQuery) {
      dispatch(getReportList(1, datedash, searchQuery));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (datedash) {
      dispatch(getReportList(1, datedash, searchQuery));
    }
  }, [datedash]);

  // useEffect(() => {
  //   if (currentPage !== 1) {
  //     dispatch(getReportList(currentPage, datedash, searchQuery));
  //   }
  // }, [currentPage, datedash]);

  const OpenRequest = (data) => {
    setOnRequest(true);
    navigate(`/attendanceoverview/${data?.user_id}`);
  };

  console.log(datedash);

  const getStatusColor = (status) => {
    switch (status) {
      case "Present":
        return "#252C58";
      case "Absent":
        return "#AA0000";
      case "Late arrival":
        return "#D5B500";
      default:
        return "#2C357D";
    }
  };

  const handleDate = (e) => {
    const selectedDate = e.target.value;
    setDateDash(selectedDate);
  };

  const reportData = allReportList?.data?.attendances || [];
  const paginationdata = allReportList;

  const filteredData = reportData.filter(
    (report) =>
      report.employee_id?.toString().includes(searchQuery) ||
      report.employee_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      report.status?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClearSelection = (e) => {
    e.stopPropagation();
    setDateDash("");
  };

  return (
    <div className="content-wrapper justify-content-start">
      <>
        <div className="card-header d-flex align-items-center justify-content-start gap-4 p-0">
          <div className="kidsselect flex">
            <h5 className="card-title m-0 me-2">Attendance Overview</h5>
          </div>
          <div className="d-flex align-items-center justify-content-start gap-3 w-100">
            <div className="table-search-bar">
              <IconSearch />
              <input
                type="text"
                className="form-control"
                style={{ border: "none" }}
                placeholder="Quick Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
              />
            </div>
            <div className="calendar_pad">
              <div className="calendar">
                <input type="date" value={datedash} onChange={handleDate} />

                {datedash && (
                  <div className="calenderClear">
                    <svg
                      width="20"
                      height="25"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={handleClearSelection}
                    >
                      <rect
                        width="60"
                        height="60"
                        rx="30"
                        fill="#2C357D"
                        fill-opacity="0.15"
                      />
                      <path
                        d="M37.75 22.1377L21.25 38.6377"
                        stroke="#2C357D"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.25 22.1377L37.75 38.6377"
                        stroke="#2C357D"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid view-requestb">
          {/* Responsive Table */}
          <div className="table-responsive">
            {pageLoading ? (
              <SpinnerLoader />
            ) : (
              <table className="table table-bordered align-middle">
                <thead>
                  <tr>
                    {[
                      { name: "Employee ID", icon: null },
                      { name: "Employee Name", icon: null },
                      { name: "Role", icon: greydrop },
                      { name: "Designation", icon: greydrop },
                      { name: "Date", icon: null },
                      { name: "Check-in", icon: greydrop },
                      { name: "Manager Checkin", icon: greydrop },
                      { name: "Manager Approve Checkin", icon: greydrop },
                      { name: "Check-out", icon: greydrop },
                      { name: "Manager Checkout", icon: greydrop },
                      { name: "Manager Approve Checkout", icon: greydrop },

                      { name: "Work hours", icon: greydrop },
                      { name: "Status", icon: greydrop },
                      { name: "Action", icon: null },
                    ].map((column, index) => (
                      <th key={index}>
                        {column.name}
                        {column.icon && (
                          <img
                            src={column.icon}
                            alt={`${column.name} dropdown icon`}
                            className="header-dropdown-icon"
                            onClick={() =>
                              console.log(`Dropdown clicked for ${column.name}`)
                            }
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                          />
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((report) => (
                    <tr key={report.id}>
                      <td>{report.employee_emp_id}</td>
                      <td>{report.employee_name}</td>
                      <td>{report.employee_role}</td>
                      <td style={{ opacity: "50%" }}>
                        {report?.employee_designation}
                      </td>
                      <td style={{ opacity: "50%" }}>
                        {moment(report.date).format("DD-MM-YYYY")}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.check_in_time
                          ? moment(report.check_in_time).format("hh:mm A")
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.manager_check_in_time
                          ? moment(report.manager_check_in_time).format(
                              "hh:mm A"
                            )
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.manager_approve_check_in_time
                          ? moment(report.manager_approve_check_in_time).format(
                              "hh:mm A"
                            )
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.check_out_time
                          ? moment(report.check_out_time).format("hh:mm A")
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.manager_check_out_time
                          ? moment(report.manager_check_out_time).format(
                              "hh:mm A"
                            )
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.manager_approve_check_out_time
                          ? moment(
                              report.manager_approve_check_out_time
                            ).format("hh:mm A")
                          : "-"}
                      </td>
                      <td style={{ color: getStatusColor(report.status) }}>
                        {report.working_hour}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            report.status === "Present"
                              ? "badge-Present"
                              : report.status === "Absent"
                              ? "badge-Absent"
                              : "badge-pending"
                          }`}
                        >
                          {report.status == "Late-Arrival"
                            ? "Late"
                            : report.status}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn-view"
                          onClick={() => OpenRequest(report)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Pagination
              listData={paginationdata?.meta}
              setPageData={setCurrentPage}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default Attendance;
