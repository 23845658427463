import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import user_icon from "../../../assets/img/user_icon.svg";
// import left_arrow from "../../../assets/img/left_arrow.png";
import filter from "../../../assets/img/filter.svg";
import move_item from "../../../assets/img/move_item.png";
import cross_box from "../../../assets/img/cross_box.png";
import sick from "../../../assets/img/sick.png";
import side_navigation from "../../../assets/img/side_navigation.png";
import beach_access from "../../../assets/img/beach_access.png";
import Check_out from "../../../assets/img/Check_out.png";
import admin from "../../../assets/img/admin.svg";
import door from "../../../assets/img/door.png";
import greydrop from "../../../assets/img/greydrop.png";
import "./dashboard.css";
import moment from "moment";

import { useState } from "react";
import {
  Sales_1,
  Kids_1,
  Kids_2,
  Kids_3,
  Kids_4,
} from "../../../assets/img/dashboard";
import {
  IconDelete,
  IconEdit,
  IconCloudUp,
  IconDown,
  IconSearch,
} from "../../../assets/img/dashboard/icons";
import { getDashboardList, getUsers } from "../../../redux/Action";
import SvgforDashboard from "../../../assets/SvgforDashboard";
import SvgIcon from "../../../assets/SvgIcon";
import SvgPath from "../../../assets/SvgPath";
import { color } from "framer-motion";
import { MdOpacity } from "react-icons/md";
import SpinnerLoader from "../../../components/common/spinnerloader/SpinnerLoader";
import { toast } from "react-toastify";
import Pagination from "../../../Common/Pagination/Pagination";

import { utils, writeFile } from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const Dashboard = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.main.getAllUsers);
  const { getAllUserList } = useSelector((state) => state?.main);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [datedash, setDateDash] = useState(moment().format("YYYY-MM-DD"));
  const [filteredData, setFilteredData] = useState([]);
  const pageLoading = useSelector((state) => state.main.loading);
  const alldashboardList = useSelector((state) => state?.main);

  useEffect(() => {
    if (!datedash) {
      setDateDash(moment().format("YYYY-MM-DD"));
    }
  }, []);
  useEffect(() => {
    dispatch(getDashboardList(currentPage, datedash));
  }, [dispatch, currentPage, datedash]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (alldashboardList?.alldashboardList?.data?.attendances) {
  //       const searchResults =
  //         alldashboardList?.alldashboardList?.data?.attendances?.filter(
  //           (employee) =>
  //             employee?.employee_name
  //               ?.toLowerCase()
  //               ?.includes(searchQuery?.toLowerCase())
  //         );
  //       setFilteredData(searchResults);
  //     }
  //   }, 300);

  //   return () => clearTimeout(timer);
  // }, [searchQuery, alldashboardList]);

  useEffect(() => {
    if (alldashboardList) {
      setFilteredData(alldashboardList?.alldashboardList?.data?.attendances);
    }
  }, [alldashboardList]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getDashboardList(currentPage, datedash, searchQuery));
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const Attendance = {
    records: [
      {
        status: "Total Employee",
        count:
          alldashboardList?.alldashboardList?.data?.totalEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.totalEmployees
            ?.growthPercentage,
        year: "Than Last Year",
        imgSrc: <img src={admin} alt={admin} />,
      },
      {
        status: "Checked In",
        count:
          alldashboardList?.alldashboardList?.data?.checkedInEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.checkedInEmployees
            ?.growthPercentage,
        year: "Than Last Year",
        imgSrc: <SvgforDashboard />,
      },
      {
        status: "Checked Out",
        count:
          alldashboardList?.alldashboardList?.data?.checkedOutEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.checkedOutEmployees
            ?.growthPercentage,
        year: "Than Last Year",
        imgSrc: <img src={Check_out} alt={Check_out} />,
      },
      {
        status: "Not Checked In",
        count:
          alldashboardList?.alldashboardList?.data?.notCheckedInEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.notCheckedInEmployees
            ?.growthPercentage,
        year: "Than Last Year",
        imgSrc: <img src={door} alt={door} />,
      },

      {
        status: "LOP leave (Loss of pay)",
        count:
          alldashboardList?.alldashboardList?.data?.lopEmployees?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.lopEmployees
            ?.growthPercentage,
        year: "Than Last Year",

        imgSrc: <img src={move_item} alt={move_item} />,
      },
      // {
      //   status: "Weekly Off",
      //   count:
      //     alldashboardList?.alldashboardList?.data?.weeklyOffEmployees
      //       ?.totalDetails,
      //   parentage:
      //     alldashboardList?.alldashboardList?.data?.weeklyOffEmployees
      //       ?.growthPercentage,
      //   year: "Than Last Year",
      //   imgSrc: <img src={cross_box} alt={cross_box} />,
      // },
      {
        status: "Absent",
        count:
          alldashboardList?.alldashboardList?.data?.absentEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.absentEmployees
            ?.growthPercentage,
        year: "Than Last Year",
        imgSrc: <img src={sick} alt={sick} />,
      },
      {
        status: "Half-Day",
        count:
          alldashboardList?.alldashboardList?.data?.halfDayEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.halfDayEmployees
            ?.growthPercentage,
        year: "Than Last Year",
        imgSrc: <img src={side_navigation} alt={side_navigation} />,
      },
      {
        status: "Holiday",
        count:
          alldashboardList?.alldashboardList?.data?.holiDayEmployees
            ?.totalDetails,
        parentage:
          alldashboardList?.alldashboardList?.data?.holiDayEmployees
            ?.growthPercentage,
        year: "Than Last Year",

        imgSrc: <img src={beach_access} alt={beach_access} />,
      },
    ],
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "Present":
        return "badge-approved";
      case "Absent":
        return "badge-rejected";
      case "Late-Arrival":
        return "badge-arrival";
      case "Half-Day":
        return "badge-arrival";
      case "Work from home":
        return "badge-arrival";
      default:
        return "";
    }
  };

  const getOpacityStyle = (status) => {
    return status === "Absent" ? { opacity: "50%" } : {};
  };

  const getTextColor = (status) => {
    return status === "Absent" ? { color: "#AA0000" } : { color: "#2C357D" };
  };
  const handleDate = (e) => {
    const selectedDate = e.target.value;
    setDateDash(selectedDate);
  };

  const handleClearSelection = (e) => {
    e.stopPropagation();
    setDateDash("");
  };

  const downloadCSV = () => {
    const data = searchQuery
      ? filteredData
      : alldashboardList?.alldashboardList?.data?.attendances || [];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      data
        .map((employee) =>
          [
            employee.employee_emp_id,
            employee.employee_name,
            employee.employee_role,
            employee.employee_department || "-",
            employee.shift?.[0]?.name || "-",
            employee.status,
            employee.check_in_time
              ? moment(employee.check_in_time).format("DD/MM/YYYY")
              : "-",
            employee.check_out_time
              ? moment(employee.check_out_time).format("DD/MM/YYYY")
              : "-",
            employee.working_hour || "-",
          ].join(",")
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "employee_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to download Excel
  const downloadExcel = () => {
    const data = searchQuery
      ? filteredData
      : alldashboardList?.alldashboardList?.data?.attendances || [];

    const worksheet = utils.json_to_sheet(
      data.map((employee) => ({
        "Employee ID": employee.employee_emp_id,
        "Employee Name": employee.employee_name,
        Role: employee.employee_role,
        Designation: employee.employee_department || "-",
        "Shift Time": employee.shift?.[0]?.name || "-",
        Status: employee.status,
        "Check-in": employee.check_in_time
          ? moment(employee.check_in_time).format("DD/MM/YYYY")
          : "-",
        "Check-out": employee.check_out_time
          ? moment(employee.check_out_time).format("DD/MM/YYYY")
          : "-",
        "Work hours": employee.working_hour || "-",
      }))
    );
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Employee Data");
    writeFile(workbook, "employee_data.xlsx");
  };

  // Function to download PDF
  const downloadPDF = () => {
    const data = searchQuery
      ? filteredData
      : alldashboardList?.alldashboardList?.data?.attendances || [];

    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "Employee ID",
          "Employee Name",
          "Role",
          "Designation",
          "Shift Time",
          "Status",
          "Check-in",
          "Check-out",
          "Work hours",
        ],
      ],
      body: data.map((employee) => [
        employee.employee_emp_id,
        employee.employee_name,
        employee.employee_role,
        employee.employee_department || "-",
        employee.shift?.[0]?.name || "-",
        employee.status,
        employee.check_in_time
          ? moment(employee.check_in_time).format("DD/MM/YYYY")
          : "-",
        employee.check_out_time
          ? moment(employee.check_out_time).format("DD/MM/YYYY")
          : "-",
        employee.working_hour || "-",
      ]),
    });
    doc.save("employee_data.pdf");
  };

  return (
    <>
      <>
        <div className="content-wrapper">
          <div className="flex-grow-1 container-p-y">
            <div className="row g-4 pb-4">
              {Attendance.records.splice(0, 4).map((record, index) => (
                <div
                  className="col-xxl-3 col-xl-3  col-lg-3 col-md-6 col-sm-12"
                  key={index}
                >
                  <div className="cardmorning">
                    <div className="totalnumber_card d-flex align-items-center">
                      <div className="left_content">
                        <div className="view-icon">{record.imgSrc}</div>
                      </div>
                      <div className="right_content">
                        <h4>{record.status}</h4>
                        <div className="min_flex">
                          <div className="min_left">
                            <h1>{record.count}</h1>
                          </div>
                          <div className="min_right d-flex align-items-center">
                            <div className="parentage_up d-flex align-items-center">
                              <SvgIcon />
                              <h5>{record.parentage}%</h5>
                            </div>
                            <div className="year">
                              <h5>{record.year}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="row g-3">
              {Attendance.records.map((record, index) => (
                <div
                  className="col-xxl-3 col-xl-3  col-lg-3 col-md-6 col-sm-12"
                  key={index}
                >
                  <div className="cardmorning2">
                    <div className="totalnumber_card d-flex align-items-center">
                      <div className="left_content">
                        <div className="view-icon">{record.imgSrc}</div>
                      </div>
                      <div className="right_content">
                        <h4>{record.status}</h4>
                        <div className="min_flex">
                          <div className="min_left">
                            <h1>{record.count}</h1>
                          </div>
                          <div className="min_right d-flex align-items-center">
                            <div className="parentage_up d-flex align-items-center">
                              <SvgIcon />
                              <h5>{record.parentage}</h5>
                            </div>
                            <div className="year">
                              <h5>{record.year}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="content-wrapper justify-content-start">
          <div className="container-fluid view-requests">
            {/* Responsive Table */}
            <div className="card-header d-flex align-items-center justify-content-center gap-3 p-0">
              <div className="kidsselect flex">
                <h5 className="card-title m-0 me-2">Attendance Overview</h5>
              </div>
              <div className="table-search-bar">
                <IconSearch />
                <input
                  type="text"
                  className="form-control"
                  style={{ border: "none" }}
                  placeholder="Quick Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="calendar_pad">
                <div className="calendar">
                  <input type="date" value={datedash} onChange={handleDate} />

                  {datedash && (
                    <div className="calenderClear">
                      <svg
                        width="20"
                        height="25"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={handleClearSelection}
                      >
                        <rect
                          width="60"
                          height="60"
                          rx="30"
                          fill="#2C357D"
                          fill-opacity="0.15"
                        />
                        <path
                          d="M37.75 22.1377L21.25 38.6377"
                          stroke="#2C357D"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21.25 22.1377L37.75 38.6377"
                          stroke="#2C357D"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="filter">
                <div className="calendar">
                  <img src={filter} alt={filter} />
                  <h4>Filter</h4>
                </div>
              </div> */}
            </div>

            {/* <div className="table-responsive mt-5">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <table className="table table-bordered align-middle">
                  <thead>
                    <tr>
                      {[
                        { name: "Employee ID", icon: null },
                        { name: "Employee Name", icon: null },
                        { name: "Role", icon: greydrop },
                        { name: "Designation", icon: greydrop },
                        { name: "Shift Time", icon: null },
                        { name: "Status", icon: greydrop },
                        { name: "Check-in", icon: greydrop },
                        { name: "Check-out", icon: greydrop },
                        { name: "Work hours", icon: greydrop },
                      ].map((column, index) => (
                        <th key={index}>
                          {column.name}
                          {column.icon && (
                            <img
                              src={column.icon}
                              alt={`${column.name} dropdown icon`}
                              className="header-dropdown-icon"
                              onClick={() =>
                                console.log(
                                  `Dropdown clicked for ${column.name}`
                                )
                              }
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(searchQuery
                      ? filteredData
                      : alldashboardList?.alldashboardList?.data?.attendances ||
                        []
                    ).map((employee, index) => {
                      const badgeClass = getStatusBadgeClass(employee.status);
                      const opacityStyle = getOpacityStyle(employee.status);
                      const textColor = getTextColor(employee.status);

                      return (
                        <tr key={index}>
                          <td>{employee.employee_emp_id}</td>
                          <td>
                            {
                              employee.employee_name
                              // .toUpperCase()
                              // .charAt(0, 10)

                              // .slice(0)
                            }
                          </td>
                          <td
                            style={{
                              opacityStyle,
                              textTransform: "capitalize",
                            }}
                          >
                            {employee.employee_role}
                          </td>
                          <td style={opacityStyle}>
                            {employee.employee_department || "-"}
                          </td>
                          <td style={opacityStyle}>
                            {employee.shift?.[0]?.name || "-"}
                          </td>
                          <td>
                            <span className={`badge ${badgeClass}`}>
                              {employee.status}
                            </span>
                          </td>
                          <td style={textColor}>
                            {employee.check_in_time
                              ? moment(employee.check_in_time).format(
                                  "DD/MM/YYYY"
                                ) // Format as day/month/year
                              : "-"}
                          </td>
                          <td style={textColor}>
                            {employee.check_out_time
                              ? moment(employee.check_out_time).format(
                                  "DD/MM/YYYY"
                                ) // Format as day/month/year
                              : "-"}
                          </td>
                          <td style={textColor}>
                            {employee.working_hour || "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              <Pagination
                listData={alldashboardList?.alldashboardList?.meta}
                setPageData={setCurrentPage}
              />
            </div> */}

            <div className="table-responsive mt-5">
              {pageLoading ? (
                <SpinnerLoader />
              ) : (
                <>
                  {/* <div className="mb-3">
                    <button
                      onClick={downloadCSV}
                      className="btn btn-primary me-2"
                    >
                      Download CSV
                    </button>
                    <button
                      onClick={downloadExcel}
                      className="btn btn-success me-2"
                    >
                      Download Excel
                    </button>
                    <button onClick={downloadPDF} className="btn btn-danger">
                      Download PDF
                    </button>
                  </div> */}
                  <table className="table table-bordered align-middle">
                    <thead>
                      <tr>
                        {[
                          { name: "Employee ID", icon: null },
                          { name: "Employee Name", icon: null },
                          { name: "Role", icon: greydrop },
                          { name: "Designation", icon: greydrop },
                          { name: "Shift Time", icon: null },
                          { name: "Status", icon: greydrop },
                          { name: "Check-in", icon: greydrop },
                          { name: "Check-out", icon: greydrop },
                          { name: "Work hours", icon: greydrop },
                        ].map((column, index) => (
                          <th key={index}>
                            {column.name}
                            {column.icon && (
                              <img
                                src={column.icon}
                                alt={`${column.name} dropdown icon`}
                                className="header-dropdown-icon"
                                onClick={() =>
                                  console.log(
                                    `Dropdown clicked for ${column.name}`
                                  )
                                }
                                style={{ marginLeft: "8px", cursor: "pointer" }}
                              />
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(searchQuery
                        ? filteredData
                        : alldashboardList?.alldashboardList?.data
                            ?.attendances || []
                      ).map((employee, index) => {
                        const badgeClass = getStatusBadgeClass(employee.status);
                        const opacityStyle = getOpacityStyle(employee.status);
                        const textColor = getTextColor(employee.status);

                        return (
                          <tr key={index}>
                            <td>{employee.employee_emp_id}</td>
                            <td>{employee.employee_name}</td>
                            <td
                              style={{
                                opacityStyle,
                                textTransform: "capitalize",
                              }}
                            >
                              {employee.employee_role}
                            </td>
                            <td style={opacityStyle}>
                              {employee.employee_department || "-"}
                            </td>
                            <td style={opacityStyle}>
                              {employee.shift?.[0]?.name || "-"}
                            </td>
                            <td>
                              <span className={`badge ${badgeClass}`}>
                                {employee.status == "Late-Arrival"
                                  ? "Late"
                                  : employee.status}
                              </span>
                            </td>
                            <td style={textColor}>
                              {employee.check_in_time
                                ? moment(employee.check_in_time).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </td>
                            <td style={textColor}>
                              {employee.check_out_time
                                ? moment(employee.check_out_time).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </td>
                            <td style={textColor}>
                              {employee.working_hour || "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    listData={alldashboardList?.alldashboardList?.meta}
                    setPageData={setCurrentPage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Dashboard;
