import Axios from "../axios";
import { toast } from "react-toastify";
import moment from "moment";
import {
  SET_GET_ALL_USERS,
  SET_LOADING,
  SET_LOGIN,
  SET_LOGOUT,
  SET_USERLIST,
  SET_ADD_USERDATA,
  SET_DETELE_USER,
  SET_FORGET_PASSWORD,
  SET_VERIFICATION,
  SET_NEWPASSWORD,
  SET_LOCALSTRORAGE_LOGIN,
  SET_REFRESHER_LOGIN,
  SET_NEWPASSWORDUSER,
  SET_LEAVEDATA,
  SET_GRIEVANCEDATA,
  SET_VIEW_USERDATA,
  SET_UPDATE_USERDATA,
  SET_ALLUSERLIST,
  SET_LEAVEDATAREJAPP,
  SET_LEAVEDELATAILSDATA,
  SET_RESIGNATIONDATA,
  SET_GRIEVANCEDETAILSDATA,
  SET_GRIEVANCEREJAPP,
  SET_RESIGNATIONDETAILSDATA,
  SET_RESIGNATIONREJAPP,
  SET_POSHDETAILSDATA,
  SET_POSHDATA,
  SET_JOBLIST,
  SET_ADD_JOBDATA,
  SET_JOBDETAILS,
  SET_REPORTLIST,
  SET_STORELIST,
  SET_STORESINGLE,
  SET_STORESINGLEUSER,
  SET_REPORTSINGLEUSER,
  SET_DASHBOARDLIST,
  SET_GRIEVANCECOMMEENT,
  SET_POSHCOMMEENT,
  SET_RESIGNATIONCOMMEENT,
  SET_LEAVECOMMEENT,
  SET_ALLCOMPANYLIST,
  SET_ADD_COMPANY,
  SET_ALLSHIFTLIST,
  SET_ADD_SHIFT,
  SET_ALLSTORELIST,
  SET_ADD_STORE,
  SET_ADD_ROLE,
  SET_ALLROLELIST,
  SET_ALLRESIGNATIONLIST,
  SET_ADD_RESINATION,
  SET_ADD_STORES,
  SET_ALL_STORE_LIST_DATA,
  SET_USER_ATTENDENCE_DATA,
  SET_DETELE_JOB,
  SET_DETELE_COMPANY,
  SET_DETELE_SHIFT,
  SET_DETELE_ROLE,
  SET_DETELE_DESIGNATION,
  SET_DETELE_STORE,
  SET_COUNTRY_LIST,
  SET_SITE_SETTING,
  SET_STATE_LIST,
  SET_CITY_LIST,
  SET_SITE_SETTING_UPDATE,
  SET_SEARCH_USER,
  SET_ADD_DOCUMENTUPLOAD,
  SET_GET_DOCUMENTUPLOAD,
  SET_DESIGNATION_UPDATE,
  SET_STORESINGLE_USER,
  SET_STATE_LIST_OPTION,
  SET_COMPANY_EDIT_DATA,
  SET_STORE_DATA,
  SET_ROLE_DATA,
  SET_SHIFT_DATA,
  SET_GET_PROFILE,
  SET_NOTIFICATION,
  SET_ALLSTORELIST_BY_COMPANY,
  SET_USER_ATTENDENCE_DATA_EMER,
  SET_STATE_LIST_EMER,
  SET_CITY_LIST_EMER,
  SET_UPDATE_JOB,
  SET_ALLRESIGNATIONLIST2,
  SET_STORESINGLE_MONTHLY,
} from "../types";

const token = localStorage.getItem("accessToken");
const role = localStorage.getItem("role");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const setPageLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};

export const setTokenInLocalStorage =
  (accessToken, refreshToken, role, adminId) => async (dispatch) => {
    dispatch({
      type: SET_LOCALSTRORAGE_LOGIN,
      payload: accessToken,
    });
    dispatch({
      type: SET_REFRESHER_LOGIN,
      payload: refreshToken,
    });

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    localStorage.setItem("role", role);
    localStorage.setItem("adminId", adminId);

    return (accessToken = accessToken);
  };

export const login = (data, router, setLoad) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.post("/admin/login", data, config)
    .then((response) => {
      if (response.status) {
        console.log(response?.data?.data?.user?.adminId, "response");
        dispatch(
          setTokenInLocalStorage(
            response.data.data.accessToken,
            response.data.data.refreshToken,
            response.data.data.user.role,
            response?.data?.data?.user?.adminId
          )
        );
        router("/");

        toast.success("Login successful");
        setLoad(false);
      } else {
        toast.error(response.data.message || "Login failed!");
        router("/login");
        setLoad(false);
      }
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "Login failed!";
      toast.error(errorMessage);
      router("/login");
      setLoad(false);
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setPageLoading(false));
        setLoad(false);
      }, 1000);
    });
};

export const logout = (data, navigate) => (dispatch) => {
  Axios.post(`/logout`, data, config)
    .then((response) => {
      if (response.status) {
        toast.success(response.data.message || "Logout successful.");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        dispatch({
          type: SET_LOGOUT,
          payload: response.data,
        });
        navigate("/login");
      } else if (response.data.login === false) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        toast.error("Session expired. Please log in again.");
        dispatch({ type: SET_LOGOUT });
        navigate("/login");
      } else {
        toast.error(response.data.message || "Something went wrong!");
      }
    })
    .catch((error) => {
      const errorMessage =
        error.response?.data?.message || "Something went wrong.";

      if (error.response?.status === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        toast.error("Unauthorized access. Please log in again.");
        dispatch({ type: SET_LOGOUT });
        navigate("/login");
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.login === false
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        toast.error("Session expired. Please log in again.");
        dispatch({ type: SET_LOGOUT });
        navigate("/login");
      } else {
        toast.error(errorMessage);
      }
    });
};

export const forgotpassword = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post("f-password", data, config)
    .then((response) => {
      if (response.status) {
        dispatch({
          type: SET_FORGET_PASSWORD,
          payload: data,
        });

        toast.success(response?.data.message);
        navigate("/verification");
        localStorage.setItem("forgotEmail", data?.email);
        dispatch(setPageLoading(false));
      } else {
        toast.error(response.message);
        navigate("/login");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      navigate("/login");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const verificationOtp = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post("verify-otp", data, config)
    .then((response) => {
      if (response.status) {
        dispatch({
          type: SET_VERIFICATION,
          payload: data,
        });
        toast.success(response?.data.message);
        navigate("/newpassword");
        dispatch(setPageLoading(false));
      } else {
        toast.error("verification failed!");
        // navigate("/forgotpassword");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("verification failed!");
      // navigate("/forgotpassword");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const newPassword = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post("/change-password", data, config)
    .then((response) => {
      if (response.status) {
        console.log(response?.data);

        dispatch({
          type: SET_NEWPASSWORD,
          payload: data,
        });
        toast.success(response?.data.message);
        navigate("/login");
        dispatch(setPageLoading(false));
      } else {
        toast.error("verification failed!");
        navigate("/verification");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("verification failed!");
      navigate("/verification");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const newPasswordUser = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`/reset-password`, data, config)
    .then((response) => {
      if (response.status) {
        dispatch({
          type: SET_NEWPASSWORDUSER,
          payload: data,
        });
        // navigate("/login");
        toast.success(response?.data.message);
        dispatch(setPageLoading(false));
      } else {
        toast.error("verification failed!");
        // navigate("/verification");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("verification failed!");
      // navigate("/verification");
      dispatch(setPageLoading(false));
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getUsers =
  (currentPage, limit, designation, name, emp, selected) => (dispatch) => {
    dispatch(setPageLoading(true));

    const queryParams = [
      `page=${currentPage}`,
      `limit=10`,
      designation ? `designation=${designation}` : "",
      name ? `name=${name}` : "",
      emp ? `emp_id=${emp}` : "",
      selected ? `searchbyrole=${selected}` : "",
    ]
      .filter(Boolean)
      .join("&");

    Axios.get(
      `/admin/users?${queryParams}`,

      config
    )
      .then((response) => {
        dispatch({
          type: SET_USERLIST,
          payload: response?.data,
        });
        // toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };
export const getUsersAll = (data, currentPage, admin) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(
    `/admin/get-all-list?page=${currentPage || 1}${
      admin ? `&admin_id=${admin}` : ""
    }`,

    data,
    config
  )
    .then((response) => {
      dispatch({
        type: SET_ALLUSERLIST,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addUsers = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`/admin/add-user`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_USERDATA,
        payload: response?.data,
      });

      navigate("/user");
      toast.success(response?.data?.result?.message);
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const updateUserData = (id, data, navigate, setLoader) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.put(`admin/update-user/${id}`, data, config)
    .then((response) => {
      toast.success(response?.data?.message);
      dispatch({
        type: SET_UPDATE_USERDATA,
        payload: response?.data,
      });
      navigate("/user");
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      setLoader(false);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      setLoader(false);
    });
};

export const userDeleteData = (data) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`/admin/delete-user/${data}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_USER,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

// export const getLeavedata = (currentPage,limit,filter) => (dispatch) => {
//   dispatch(setPageLoading(true));
//   Axios.get(`/admin/get-leaves?page=${currentPage}`, config)
//     .then((response) => {
//       // toast.success(response?.data?.result?.message);

//       dispatch({
//         type: SET_LEAVEDATA,
//         payload: response?.data,
//       });
//       setPageLoading(false);
//     })
//     .catch((error) => {
//       toast.error(error?.response?.data?.message || "An error occurred");
//     })
//     .finally(() => {
//       dispatch(setPageLoading(false));
//     });
// };

export const getLeavedata = (currentPage, limit, filter) => (dispatch) => {
  dispatch(setPageLoading(true));

  // Build query string dynamically
  let query = `/admin/get-leaves?page=${currentPage}&limit=${limit}`;
  if (filter) {
    query += `&filter=${filter}`;
  }

  Axios.get(query, config)
    .then((response) => {
      dispatch({
        type: SET_LEAVEDATA,
        payload: response?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getLeaveDetailsdata = (id, data) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`leave-details/${id}`, config)
    .then((response) => {
      // toast.success(response?.data?.result?.message);

      dispatch({
        type: SET_LEAVEDELATAILSDATA,
        payload: response?.data,
      });
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const putRejacedApprovedata = (status, id, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.put(`/admin/modify-leave-status/${id}?status=${status}`, config)
    .then((response) => {
      toast.success(response?.data?.message);

      dispatch({
        type: SET_LEAVEDATAREJAPP,
        payload: response?.data,
      });
      navigate("/requests");
      setPageLoading(false);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getGrievancedata = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/get-admin-grievance?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_GRIEVANCEDATA,
        payload: response?.data,
      });
      // toast.success(
      //   response?.data?.message || "Grievance data retrieved successfully."
      // ); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to retrieve grievance data."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getGrievanceDetaildata = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`/get-single-grievance/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_GRIEVANCEDETAILSDATA,
        payload: response?.data,
      });
      // toast.success(
      //   response?.data?.message || "Grievance details retrieved successfully."
      // ); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message ||
          "Failed to retrieve grievance details."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const patchGrievanceRejacedApprovedata = (status, id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.patch(`/admin/modify-grievance/${id}?status=${status}`, config)
    .then((response) => {
      dispatch({
        type: SET_GRIEVANCEREJAPP,
        payload: response?.data,
      });
      toast.success(
        response?.message || "Grievance status updated successfully."
      ); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.message || "Failed to update grievance status."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getResignationdata = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/get-user-resignation?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_RESIGNATIONDATA,
        payload: response?.data,
      });
      // toast.success("Resignation data retrieved successfully."); // Added toast notification
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to retrieve resignation data."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getNotification = () => (dispatch) => {
  Axios.get(`admin/get-notification`, config)
    .then((response) => {
      dispatch({
        type: SET_NOTIFICATION,
        payload: response?.data,
      });
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to retrieve  data."
      ); // Added toast notification
    })
    .finally(() => {});
};

export const getResignationDetaildata = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`get-single-resignation/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_RESIGNATIONDETAILSDATA,
        payload: response?.data,
      });
      // toast.success("Resignation details retrieved successfully.");
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message ||
          "Failed to retrieve resignation details."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const putResignationRejacedApprovedata = (status, id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.patch(`/admin/modify-resignation/${id}?status=${status}`, config)
    .then((response) => {
      console.log(response, "response");
      dispatch({
        type: SET_RESIGNATIONREJAPP,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.message || "Failed to update resignation status."
      ); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getPoshdata = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/get-posh-admin?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_POSHDATA,
        payload: response?.data,
      });
      // toast.success("Resignation data retrieved successfully.");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getPoshDetaildata = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`get-single-posh/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_POSHDETAILSDATA,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getjobList = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/get-all-job?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_JOBLIST,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getjobdetailList = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/get-single-job-admin/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_JOBDETAILS,
        payload: response?.data,
      });
      // toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addJobs = (data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`/admin/add-job`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_JOBDATA,
        payload: response?.data,
      });

      navigate("/job");
      toast.success(response?.data?.result?.message);
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

// export const getReportList = (currentPage, datedash) => (dispatch) => {
//   const formattedDate = moment(datedash).format("DD-MM-YYYY");
//   const dateParam = datedash ? `&date=${formattedDate}` : "";

//   const url = currentPage
//     ? `admin/get-emps-report?page=${currentPage}${dateParam}`
//     : `admin/get-emps-report${dateParam}`;

//   dispatch(setPageLoading(true));

//   Axios.get(url, config)
//     .then((response) => {
//       dispatch({
//         type: SET_REPORTLIST,
//         payload: response?.data,
//       });
//       dispatch(setPageLoading(false));

//       // toast.success();
//     })
//     .catch((error) => {
//       dispatch(setPageLoading(false));
//       toast.error(error?.response?.data?.message); // Added toast notification
//     })
//     .finally(() => {
//       dispatch(setPageLoading(false));
//     });
// };

export const getReportList =
  (currentPage, datedash, employeeName) => (dispatch) => {
    const formattedDate = datedash ? moment(datedash).format("DD-MM-YYYY") : "";
    const dateParam = formattedDate ? `&date=${formattedDate}` : "";
    const nameParam = employeeName
      ? `&name=${encodeURIComponent(employeeName)}`
      : "";

    const url = `admin/get-emps-report?page=${currentPage}${dateParam}${nameParam}`;

    dispatch(setPageLoading(true));

    Axios.get(url, config)
      .then((response) => {
        dispatch({
          type: SET_REPORTLIST,
          payload: response?.data,
        });
        dispatch(setPageLoading(false));
      })
      .catch((error) => {
        dispatch(setPageLoading(false));
        toast.error(error?.response?.data?.message); // Show error notification
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

export const getStoreList = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/management-store-report?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_STORELIST,
        payload: response?.data,
      });
      // toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getStoreSingleDetails = (id, currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/store-emps-report/${id}?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_STORESINGLE,
        payload: response?.data,
      });
      // toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getStoreDataMonthly =
  (id, selectedMonthYear, search, currentPage, designationId) => (dispatch) => {
    dispatch(setPageLoading(true));
    const formattedMonth = String(selectedMonthYear?.month + 1).padStart(
      2,
      "0"
    );
    const formattedYear = selectedMonthYear?.year;

    let url = `admin/get-emps-reportMonthly/?monthYear=${formattedMonth}-${formattedYear}&page=${currentPage}`;

    // Append search query only if it exists
    if (search) {
      url += `&search=${encodeURIComponent(search)}`;
    }

    // Append designationId query if it exists
    if (designationId) {
      url += `&designationId=${designationId}`;
    }
    if (id) {
      url += `&storeId=${id}`;
    }

    Axios.get(url, config)
      .then((response) => {
        dispatch({
          type: SET_STORESINGLE_MONTHLY,
          payload: response?.data,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

export const getStoreSingleUser = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/single-user/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_STORESINGLE_USER,
        payload: response?.data?.data,
      });
      // toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getReportSingleUserdata = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/single-user/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_REPORTSINGLEUSER,
        payload: response?.data?.data,
      });
      // toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message); // Added toast notification
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getDashboardList =
  (currentPage, datedash, searchQuery) => (dispatch) => {
    const formattedDate = datedash
      ? `&date=${moment(datedash).format("DD-MM-YYYY")}`
      : "";
    const search = searchQuery
      ? `&search=${encodeURIComponent(searchQuery)}`
      : "";

    dispatch(setPageLoading(true));

    Axios.get(
      `admin/dashboard?page=${currentPage}${formattedDate}${search}`,
      config
    )
      .then((response) => {
        dispatch({
          type: SET_DASHBOARDLIST,
          payload: response?.data,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

export const addCommentforGrievance =
  (data, userId, navigate, setCommentValue) => (dispatch) => {
    dispatch(setPageLoading(true));
    Axios.put(`admin/add-comment-grievance/${userId}`, data, config)
      .then((response) => {
        dispatch({
          type: SET_GRIEVANCECOMMEENT,
          payload: response?.data,
        });
        toast.success();
        setCommentValue("");
        navigate("/grievance");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };
export const addCommentforResignation =
  (data, userId, navigate, setCommentValue) => (dispatch) => {
    dispatch(setPageLoading(true));
    Axios.put(`admin/add-comment-resignation/${userId}`, data, config)
      .then((response) => {
        dispatch({
          type: SET_RESIGNATIONCOMMEENT,
          payload: response?.data,
        });
        toast.success();
        setCommentValue("");
        navigate("/resignation");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };
export const addCommentforPosh =
  (data, userId, navigate, setCommentValue) => (dispatch) => {
    dispatch(setPageLoading(true));
    Axios.post(`admin/add-comment-posh/${userId}`, data, config)
      .then((response) => {
        dispatch({
          type: SET_POSHCOMMEENT,
          payload: response?.data,
        });
        toast.success(response?.data?.message);

        setCommentValue("");
        navigate("/posh");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message); // Added toast notification
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };
export const addCommentforLeave =
  (data, userId, navigate, setCommentValue) => (dispatch) => {
    dispatch(setPageLoading(true));
    Axios.put(`admin/add-comment-leave/${userId}`, data, config)
      .then((response) => {
        dispatch({
          type: SET_LEAVECOMMEENT,
          payload: response?.data,
        });
        toast.success(response?.data?.message);

        setCommentValue("");
        navigate("/requests");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message); // Added toast notification
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };
export const addNewStore = (data, setOpenInviteMember) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`admin/store-add`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_STORES,
        payload: response?.data,
      });

      setOpenInviteMember(false);
      toast.success(response?.data?.message);
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getAllStores = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/store-list`, config)
    .then((response) => {
      dispatch({
        type: SET_ALL_STORE_LIST_DATA,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getSettingComapnay = (currentPage, data) => (dispatch) => {
  dispatch(setPageLoading(true));
  console.log(currentPage, "currentPage");
  Axios.get(`admin/company-list?page=${currentPage}`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ALLCOMPANYLIST,
        payload: response?.data,
      });
      toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addSettingCompany = (data, navigate, onClose) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`admin/add-company`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_COMPANY,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
      onClose(true);
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getSettingShift = (currentPage, data) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/get-all-shifts?page=${currentPage}`, data, config)
    .then((response) => {
      console.log(response, "response");
      dispatch({
        type: SET_ALLSHIFTLIST,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addSettingShift = (data, navigate, onClose) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`admin/add-shift`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_SHIFT,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
      onClose(true);
      // window.location.reload();

      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getSettingStore = (currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/store-list?page=${currentPage}`, config)
    .then((response) => {
      dispatch({
        type: SET_ALLSTORELIST,
        payload: response?.data,
      });
      // toast.success();
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getStoreByCompany = (id) => (dispatch) => {
  Axios.get(`admin/store-by-company/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_ALLSTORELIST_BY_COMPANY,
        payload: response?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {});
};
export const addSettingStore = (data, navigate, onClose) => (dispatch) => {
  dispatch(setPageLoading(true));
  console.log("rohit");

  Axios.post(`admin/store-add`, data, config)
    .then((response) => {
      console.log({ response }, "rohit");
      console.log("rohit");

      dispatch({
        type: SET_ADD_STORE,
        payload: response?.data,
      });
      console.log({ response }, "rohit");

      toast.success(response?.data?.message);
      onClose(true);
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getSettingRole = (currentPage, data) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/role-list?page=${currentPage}`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ALLROLELIST,
        payload: response?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addSettingRole = (data, navigate, onClose) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`admin/role-add`, data, config)
    .then((response) => {
      dispatch({
        type: SET_ADD_ROLE,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
      onClose(true);
      setPageLoading(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })

    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getSettingResignation = (currentPage, data) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/designation-list?page=${currentPage}`, data, config)
    .then((response) => {
      console.log(response, "response");
      dispatch({
        type: SET_ALLRESIGNATIONLIST,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getSettingResignation2 = (currentPage, data) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/designation-list`, data, config)
    .then((response) => {
      console.log(response, "response");
      dispatch({
        type: SET_ALLRESIGNATIONLIST2,
        payload: response?.data,
      });
      // toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const addSettingResignation =
  (data, navigate, onClose) => (dispatch) => {
    dispatch(setPageLoading(true));
    Axios.post(`admin/designation-add`, data, config)
      .then((response) => {
        dispatch({
          type: SET_ADD_RESINATION,
          payload: response?.data,
        });

        toast.success(response?.data?.result?.message);
        onClose(true);
        setPageLoading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })

      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

export const getAllCountryList = () => (dispatch) => {
  Axios.get(`admin/get-all-countries`, config)
    .then((response) => {
      dispatch({
        type: SET_COUNTRY_LIST,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};

export const getAllStateListEmer = (id) => (dispatch) => {
  Axios.get(`admin/get-state-by-country/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_STATE_LIST_EMER,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllStateList = (id) => (dispatch) => {
  Axios.get(`admin/get-state-by-country/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_STATE_LIST,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllStateListFor = (id) => (dispatch) => {
  Axios.get(`admin/get-state-by-country/673c19018d0abfbe5488b131`, config)
    .then((response) => {
      dispatch({
        type: SET_STATE_LIST_OPTION,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const editCompany = (id, data, onClose) => (dispatch) => {
  Axios.put(`admin/edit-company/${id}`, data, config)
    .then((response) => {
      dispatch({
        type: SET_COMPANY_EDIT_DATA,
        payload: response?.data,
      });
      toast.success(response?.data?.message);

      onClose(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const editStore = (id, data, onClose) => (dispatch) => {
  Axios.put(`admin/store-update/${id}`, data, config)
    .then((response) => {
      console.log(response, "kkkooj");
      dispatch({
        type: SET_STORE_DATA,
        payload: response?.data,
      });
      toast.success(response?.data?.message);

      onClose(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const editRole = (id, data, onClose) => (dispatch) => {
  Axios.post(`admin/role-update/${id}`, data, config)
    .then((response) => {
      console.log(response, "kkkooj");

      dispatch({
        type: SET_ROLE_DATA,
        payload: response?.data,
      });
      toast.success(response?.data?.message);

      onClose(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const editShift = (id, data, onClose) => (dispatch) => {
  Axios.put(`admin/update-shift/${id}`, data, config)
    .then((response) => {
      dispatch({
        type: SET_SHIFT_DATA,
        payload: response?.data?.data,
      });
      toast.success(response?.data?.message);

      onClose(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllCityList = (id) => (dispatch) => {
  Axios.get(`admin/get-city-by-state/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_CITY_LIST,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllCityListEmer = (id) => (dispatch) => {
  Axios.get(`admin/get-city-by-state/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_CITY_LIST_EMER,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getUserAttendenceData = (id, currentPage) => (dispatch) => {
  dispatch(setPageLoading(true));

  Axios.get(`admin/emp-attend-report/${id}`, config)
    .then((response) => {
      dispatch({
        type: SET_USER_ATTENDENCE_DATA,
        payload: response?.data,
      });
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const jobdeleteData = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`admin/delete-job/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_JOB,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const companydeleteData = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`admin/delete-company/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_COMPANY,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const storedeleteData = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`admin/delete-store/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_STORE,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const designationdeleteData = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`admin/delete-designation/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_DESIGNATION,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const roledeleteData = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`admin/delete-role/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_ROLE,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const shiftdeleteData = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.delete(`admin/delete-shift/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DETELE_SHIFT,
          payload: res?.data,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const getSiteSettings = () => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`/admin/setting`, config)
    .then((response) => {
      dispatch({
        type: SET_SITE_SETTING,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const updateSiteSettings = (data) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.post(`/admin/setting`, data, config)
    .then((response) => {
      toast.success(response?.data?.data);
      dispatch({
        type: SET_SITE_SETTING_UPDATE,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const updateJobs = (userId, data, navigate) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.put(`/admin/update-job/${userId}`, data, config)
    .then((response) => {
      toast.success(response?.data?.data);
      dispatch({
        type: SET_UPDATE_JOB,
        payload: response?.data?.data,
      });
      navigate("/job");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getSearchUser = (data) => (dispatch) => {
  // dispatch(setPageLoading(true));
  Axios.get(`admin/users-search?name=${data}`, config)
    .then((response) => {
      dispatch({
        type: SET_SEARCH_USER,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      // dispatch(setPageLoading(false));
    });
};
export const addDocumentUpload = (data, type, docitem) => async (dispatch) => {
  dispatch(setPageLoading(true));
  try {
    const response = await Axios.post(
      docitem
        ? `admin/upload-document/${docitem}?type=${type}`
        : `admin/upload-document?type=${type}`,
      data,
      config
    );

    dispatch({
      type: SET_ADD_DOCUMENTUPLOAD,
      payload: response?.data,
    });

    toast.success(response?.data?.result?.message);

    const docxId = response?.data?.data?._id;
    localStorage.setItem("docxid", docxId);

    dispatch(setPageLoading(false));

    return { success: true, type };
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch(setPageLoading(false));

    return { success: false, type };
  }
};

export const getDocumentUpload = (nextid) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/get-temp-document/${nextid}`, config)
    .then((response) => {
      dispatch({
        type: SET_GET_DOCUMENTUPLOAD,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};

export const updateDesignation = (id, data, onClose) => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.put(`admin/designation-edit/${id}`, data, config)
    .then((response) => {
      toast.success(response?.data);
      onClose(false);
      dispatch({
        type: SET_DESIGNATION_UPDATE,
        payload: response?.data,
      });
    })

    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
export const getProfile = () => (dispatch) => {
  dispatch(setPageLoading(true));
  Axios.get(`admin/profile`, config)
    .then((response) => {
      console.log(response, "response");
      dispatch({
        type: SET_GET_PROFILE,
        payload: response?.data?.data,
      });
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
    });
};
