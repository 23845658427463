import React, { useEffect, useState } from "react";
import "./addstore.css";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  addSettingStore,
  editStore,
  getSettingComapnay,
} from "../../redux/Action";
import { toast } from "react-toastify";
import MultiSelector from "../../components/common/selects/MultiSelector";
import { background } from "@chakra-ui/react";

const AddStoreNew = ({ setOpenInviteMember, onClose, updatedData, status }) => {
  const dispatch = useDispatch();
  const { allCompanylist } = useSelector((state) => state.main);

  useEffect(() => {
    dispatch(getSettingComapnay());
  }, [dispatch]);

  const fields = [
    { label: "Store Name", type: "text", name: "name" },
    {
      label: "Company Name",
      type: "select",
      name: "company_id",
      options: allCompanylist?.data?.map((company) => ({
        label: company.company_name,
        value: company.id,
        status: company.status,
      })),
    },
    { label: "Store Address", type: "text", name: "address" },
    {
      label: "Store Days Off",
      type: "select",
      name: "weakly_off",
    },
    {
      label: "Status",
      type: "select",
      name: "status",
      options: [
        { label: "Active", value: true, status: true },
        { label: "Inactive", value: false, status: true },
      ],
    },
  ];

  const options = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  console.log(updatedData, "updatedData");

  const [formData, setFormData] = useState({
    name: updatedData?.name || "",
    company_id: updatedData?.company_id || "",
    address: updatedData?.address || "",
    weakly_off: Array.isArray(updatedData?.weakly_off)
      ? updatedData.weakly_off
      : [], // Ensure it's an array
    status: updatedData?.status ?? null,
  });

  // const handleChange = (e) => {
  //   console.log(e, "e");
  //   const { name, value, files } = e.target;
  //   console.log(value?.value, "name");
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: files ? files[0] : value,
  //   }));
  // };
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: files
        ? files[0] // If there are files, take the first file
        : Array.isArray(value)
        ? value // If the value is an array (multi-select), update it directly
        : value, // For other types of values (text, single-select)
    }));
  };

  const clickSubmitHandler = () => {
    if (status === "add") {
      if (formData?.weakly_off.length !== 0) {
        dispatch(addSettingStore(formData, setOpenInviteMember, onClose));
      } else {
        toast.error("Store days off");
      }
    } else if (status === "edit") {
      if (formData?.weakly_off.length !== 0) {
        dispatch(editStore(updatedData?._id, formData, onClose));
      } else {
        toast.error("select weekly off");
      }
    }
  };

  return (
    <>
      <div className="addstore">
        <div className="d-flex justify-content-between">
          <h2>{status === "add" ? "Add Store" : "Edit Store"}</h2>
          <VscClose size={20} onClick={onClose} />
        </div>
      </div>
      <div className="border-bottom"></div>
      <div
        className="d-flex justify-content-between gap-1 content_common"
        style={{ borderBottom: "2px solid #2C357D2E" }}
      >
        <div className=" label_name">
          {fields.map((field) => (
            <label key={field.name} htmlFor={field.name}>
              {field.label}
            </label>
          ))}
        </div>
        <div className="input_group mx-auto">
          {fields.map((field) => (
            <div key={field.name} className="form-group">
              {field.name === "weakly_off" ? (
                <MultiSelector
                  name="weakly_off"
                  value={formData.weakly_off || []}
                  onChange={handleChange}
                  options={options}
                  multi={true}
                  placeholder="Select Days"
                />
              ) : field.type === "select" ? (
                <select
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option hidden>Select {field.label}</option>
                  {field.options?.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                      disabled={!option.status}
                      style={{
                        background: !option.status ? "#d9d6d6" : "initial",
                      }}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : field.type === "file" ? (
                <input
                  type="file"
                  name={field.name}
                  accept="image/*"
                  onChange={handleChange}
                  className="form-control"
                />
              ) : (
                <input
                  className="form-control"
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.label}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="btnblue px-4 pb-5 mt-1">
        <button onClick={clickSubmitHandler}>Submit</button>
      </div>
    </>
  );
};

export default AddStoreNew;
