// AttendanceConsolidated.js
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./attendanceConsolidated.css";
import Checkbox from "../../components/common/checkbox/checkbox";
import Dropdown from "../../components/common/customDropdown/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";

import * as XLSX from "xlsx"; // Corrected import
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingComapnay,
  getSettingResignation2,
  getStoreByCompany,
  getStoreDataMonthly,
} from "../../redux/Action";
import Pagination from "../../Common/Pagination/Pagination";
import { useDebounce } from "../../Common/custom-hooks/UseDbounce";

const AttendanceConsolidated = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedMonthYear, setSelectedMonthYear] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [currentPage, setCurrentPage] = useState(1);

  const {
    monthlyStoreData,
    allCompanylist,
    storeListByCompany,
    allResignationList2,
  } = useSelector((state) => state.main);

  const [selectedCompanyValue, setSelectedCompanyValue] = useState();
  const [selectedStoreValue, setSelectedStoreValue] = useState();
  const [designationId, setDesignationId] = useState();
  const [checked, setChecked] = useState(false);
  const [employees, setEmployess] = useState([]);
  const [search, setSearch] = useState();
  const [showButtons, setShowButtons] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  console.log(allResignationList2);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const months = moment.months();

  const years = [];
  for (let i = currentYear; i >= currentYear - 5; i--) {
    years.push(i);
  }
  const userRole = localStorage.getItem("role");

  useEffect(() => {
    dispatch(getSettingComapnay());
    dispatch(getSettingResignation2());
  }, [dispatch, userRole]);

  useEffect(() => {
    if (userRole === "management") {
      const id = selectedStoreValue?.value;
      dispatch(
        getStoreDataMonthly(
          id,
          selectedMonthYear,
          debouncedSearch,
          currentPage,
          designationId?.value
        )
      );
    }
  }, [
    dispatch,
    userRole,
    currentPage,
    selectedStoreValue,
    selectedMonthYear,
    designationId,
    debouncedSearch,
  ]);

  useEffect(() => {
    if (userRole === "management" && monthlyStoreData) {
      setEmployess(monthlyStoreData?.data?.storeUsers);
    }
  }, [dispatch, userRole, currentPage, monthlyStoreData]);

  useEffect(() => {
    if (userRole === "management" && selectedCompanyValue?.value) {
      dispatch(getStoreByCompany(selectedCompanyValue?.value));
    }
  }, [dispatch, userRole, currentPage, selectedCompanyValue]);

  const handleStoreSelector = (value) => {
    setSelectedStoreValue(value);
    setCurrentPage(1);
  };
  const handleCompanySelector = (value) => {
    setSelectedCompanyValue(value);
  };

  const handleChange = (value) => {
    const [month, year] = value?.value?.split(" ");
    setSelectedMonthYear({
      month: months.indexOf(month),
      year: parseInt(year),
    });
    setCurrentPage(1);
  };

  const getDaysInMonth = (month, year) => {
    const days = [];
    const daysInMonth = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      const dayOfWeek = moment(
        `${year}-${month + 1}-${i}`,
        "YYYY-MM-DD"
      ).format("ddd");
      const fullDate = moment(`${year}-${month + 1}-${i}`, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      days.push({ day: i, dayOfWeek, fullDate });
    }
    return days;
  };

  const days = getDaysInMonth(selectedMonthYear.month, selectedMonthYear.year);

  const allcompanylists = allCompanylist?.data?.map((data) => {
    return {
      label: data.company_name,
      value: data.id,
      disabled: data?.status == true ? false : true,
    };
  });
  const allStorelists = storeListByCompany?.data?.map((data) => {
    return {
      label: data.name,
      value: data._id,
      disabled: data?.status == true ? false : true,
    };
  });
  const designationlist = allResignationList2?.data?.map((data) => {
    return {
      label: data.name,
      value: data._id,
      disabled: data?.status == true ? false : true,
    };
  });

  const handleDesignationChange = (value) => {
    setDesignationId(value);
    setCurrentPage(1);
  };

  const profileShowHandler = () => {
    setChecked(!checked);
  };

  const searchHandler = (value) => {
    console.log(value);

    setSearch(value);
  };

  const handleExportExcel = () => {
    const table = document.getElementById("attendance-table");
    const ws = XLSX.utils.table_to_sheet(table);

    // Set column widths dynamically
    const wscols = [
      { wch: 20 }, // Column 1
      { wch: 30 }, // Column 2
      { wch: 25 }, // Column 3
    ];
    ws["!cols"] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance Data");

    XLSX.writeFile(wb, "attendance.xlsx");
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("l", "mm", "a4"); // 'l' for landscape mode
    const table = document.getElementById("attendance-table"); // Get table element

    // Get table data and generate PDF
    doc.autoTable({
      html: table, // Automatically use the table HTML to create the table in the PDF
      startY: 30, // Adjust the Y-position to start below any header
      theme: "grid", // Optional: Adds grid style to the table
      margin: { top: 20, left: 20, right: 20, bottom: 20 }, // Adjust margins to fit content in landscape
      styles: {
        fontSize: 10,
        cellWidth: "auto",
      },
      columnStyles: {
        // Customize column widths if needed to ensure text fits in cells
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        // Add more column styles as per your table
      },
    });

    // Save the PDF
    doc.save("attendance_landscape.pdf");
  };

  const handleExportCSV = () => {
    const ws = XLSX.utils.table_to_sheet(
      document.getElementById("attendance-table")
    ); // Use the table element directly
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance");

    // Write CSV
    XLSX.writeFile(wb, "attendance.csv");
  };

  const showmoreButtons = () => {
    setShowButtons(!showButtons);
  };

  return (
    <div className="content-wrapper">
      <div className="store_card">
        <div className="selector_for_table">
          <div className="month">
            <h5>Select Month & Year</h5>
            <Dropdown
              value={`${months[selectedMonthYear.month]} ${
                selectedMonthYear.year
              }`}
              onChange={handleChange}
              data={years.flatMap((year) =>
                months.map((month) => ({
                  label: `${month} ${year}`,
                  value: `${month} ${year}`,
                }))
              )}
              clearSection={setSelectedMonthYear}
              hide={true}
            />
          </div>

          <div className="company">
            <h5>Select Company</h5>
            <Dropdown
              value={selectedCompanyValue?.label}
              onChange={(selectedValue) => {
                handleCompanySelector(selectedValue);
              }}
              data={allcompanylists}
              clearSection={setSelectedCompanyValue}
            />
          </div>

          <div className="store">
            <h5>Select Store</h5>
            <Dropdown
              value={selectedStoreValue?.label}
              onChange={(selectedValue) => {
                handleStoreSelector(selectedValue);
              }}
              data={allStorelists}
              clearSection={setSelectedStoreValue}
            />
          </div>

          <div className="employee">
            <h5>Search</h5>
            <Dropdown
              value={search}
              onChange={(e) => searchHandler(e.target.value)}
              type="input"
            />
          </div>

          <div className="designation">
            <h5>Designation</h5>
            <Dropdown
              value={designationId?.label}
              onChange={(e) => {
                handleDesignationChange(e);
              }}
              data={designationlist}
              clearSection={setDesignationId}
            />
          </div>

          <div className="checkbox">
            <div className="checkbox_text">
              <Checkbox checked={checked} onChange={profileShowHandler} />
              <p>With Profile</p>
            </div>
            <div style={{ position: "relative" }}>
              <button onClick={showmoreButtons}>Export</button>
              {showButtons && (
                <div style={{ position: "absolute" }} className="exportbtnss">
                  <ul>
                    <li onClick={handleExportCSV}>csv</li>
                    <li onClick={handleExportExcel}>xlsx</li>
                    <li onClick={handleExportPDF}>pdf</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="table_attendance">
          <table id="attendance-table">
            <thead>
              <tr>
                <th
                  style={{
                    borderLeft: "none",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  Employee
                </th>
                {days.map((day, index) => (
                  <th
                    key={index}
                    style={{
                      color: "#252C58",
                      fontSize: "10px",
                      fontWeight: "500",
                      lineHeight: "15px",
                      textAlign: "center",
                    }}
                  >
                    {day.day} <br /> {day.dayOfWeek}
                  </th>
                ))}
                <th
                  style={{
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  P
                </th>
                <th
                  style={{
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  LT
                </th>
                <th
                  style={{
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  HD
                </th>
                <th
                  style={{
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  A
                </th>
                <th
                  style={{
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  L <br /> PL
                </th>
                <th
                  style={{
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  L <br /> LOP
                </th>
                <th
                  style={{
                    borderRight: "none",
                    color: "#252C58",
                    fontSize: "10px",
                    fontWeight: "500",
                    lineHeight: "15px",
                    textAlign: "center",
                  }}
                >
                  WO
                </th>
              </tr>
            </thead>
            <tbody>
              {employees?.map((employee) => (
                <tr key={employee.id}>
                  {console.log(employee)}
                  <td
                    style={{
                      borderLeft: "none",
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      textAlign: "left",
                    }}
                  >
                    <div className="d-flex justify-content-start gap-2">
                      {checked && (
                        <div>
                          {employee.employee_profileImage ? (
                            <img
                              src={employee.employee_profileImage}
                              alt=""
                              style={{
                                width: "40px",
                                height: "auto",
                              }}
                            />
                          ) : (
                            <span
                              className="icon"
                              style={{
                                display: employee.employee_profileImage
                                  ? "none"
                                  : "block",
                                fontSize: "8px",
                                color: "#7182B6",
                                background: "#dddddd",
                                height: "40px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "8px",
                                }}
                              >
                                No image{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "8px",
                                }}
                              >
                                available
                              </p>
                            </span>
                          )}
                        </div>
                      )}
                      <div>
                        <span style={{ color: "#2C357D" }}>
                          {employee.employee_name}
                        </span>{" "}
                        <br />
                        <span style={{ color: "#7182B6" }}>
                          {employee.employee_designation}
                        </span>
                      </div>
                    </div>
                  </td>
                  {days.map(({ fullDate }, index) => (
                    <td key={index}>
                      {(() => {
                        const attendanceEntry =
                          employee?.attendance_by_day.find(
                            (item) => item.day == fullDate
                          );

                        const status = attendanceEntry?.attendance[0]?.status;

                        // Define the mapping of statuses to display values
                        const statusMap = {
                          Present: "P",
                          "Late-Arrival": "LT",
                          Absent: "A",
                          "Half-Day": "HD",
                        };

                        return statusMap[status] || "-"; // Default to "-" if status is not found
                      })()}
                    </td>
                  ))}

                  <td>{employee?.total_present}</td>
                  <td>{employee?.total_late_days}</td>
                  <td>{employee?.total_half_day}</td>
                  <td>{employee?.total_absent}</td>
                  <td>{employee?.total_on_leave}</td>
                  <td>L LOP</td>
                  <td style={{ borderRight: "none" }}>WO</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        listData={monthlyStoreData?.meta}
        setPageData={setCurrentPage}
      />
    </div>
  );
};

export default AttendanceConsolidated;
